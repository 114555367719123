@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

body {
  @apply bg-gray-100 text-gray-800;
  font-family: 'Inter', sans-serif;
}

/* Floating scrollbar */

html {
  @apply overflow-y-overlay;
}

::-webkit-scrollbar {
  background: 0;
}

::-webkit-scrollbar {
  @apply h-[10px] w-[10px];
}

::-webkit-scrollbar-thumb {
  @apply rounded-md bg-gray-800/30;
}

/* ---------- */

th {
  @apply font-normal;
}
